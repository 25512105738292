.popUpScreen{
    width: 100%;
    top: 0px;
    left: 0px;
    /* bottom: 0px; */
    /* flex: 100%; */
    height: 100%;
    position: fixed;
    background-color: #00000080;
    
    display: flex;
    flex-direction: column;
    align-items: center;
}


.bookingPopUp{
    
    position: absolute;
    top: 10%;
    /* bottom: 0px; */
    /* margin-bottom: 100px; */
    width: 835px;
    background-color: white;
    border-radius: 12px;
    padding: 32px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
}



.bookingPopUpTopRow{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.bookingPopUpTitle{
    font-weight: 600;
    font-size: 24px;
}

.bookingPopUpCancel{
    cursor: pointer;
    height: 24px;
}

.bookingPopUpLine{
    height: 1px;
    width: 100%;
    background-color: #EBEBEB;
}


.bookingPopUpContent{
    width: 100%;
    min-height: 300px;
    max-height: 600px;
    overflow-y: auto;
}






.bookingPopUp1{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    gap: 23px;
}

.bookingPopUp2{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 15px;
    gap: 40px;
}


.bookingCouponSection{
    height: 100px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
}

.bookingCouponRow{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
}

.bookingCouponInput
{
    width: 316px;
    height: 51px;
    border-radius: 12px;
    background-color: #EFFAFC;
    border-color: #01A4BD;
    color: #01A4BD;
    font-size: 18px;
    font-weight: 400;
    padding-left: 10px;
}
.bookingCouponInput.applied{
    display: flex;
    justify-content: center;
    align-items: center;
}

.bookingCouponBtn
{
    width: 132.81px;
    height: 51px;
    border-radius: 12px;
    background-color: #EFFAFC;
    border-color: #01A4BD;
    color: #01A4BD;
    font-size: 18px;
    font-weight: 400;
    cursor: pointer;
    transition: all 0.3s;
}

.bookingCouponBtn:hover{
    background-color: #01A4BD;
    border-color: #EFFAFC;
    color: #EFFAFC;
}

.BPUstep2Row{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 7px;
    font-size: 18px;
    font-weight: 400;
}


.BPUstep2Dot{
    height: 9px;
    width: 9px;
    border-radius: 50%;
    background-color: #01A4BD;
    border-color: transparent;
}


.BPUstep2Title{
    font-weight: 500;
}

.bookingPopUp3{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
}

.bookingPopUpTutor{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 24px;
}

.bookingPopUpImg{
    height: 92px;
    width: 92px;
    border-radius: 12px;
    border-color: transparent;
    object-fit: cover;
}

.bookingPopUpTutorColumn{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
}

.bookingPopUpName{
    font-weight: 600;
    font-size: 20px;
}

.bookingPopUpJob{
    color: #00000080;
    font-weight: 500;
    font-size: 16px;
}

.paymentSectionBPU
{
    width: 100%;
    display: flex;
    justify-content: center;
}

.paymentComponentBPU{
    height: 339px;
}

.paymentForm{
    display: flex;
    flex-direction: row;
    gap: 30px;
    
}

.billingAddressDiv{
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    font-weight: 400;
    font-size: 15px;
    color: #30313C;
}


.billingAddressInput{
    width: 95%;
    height: 49px;
    background-color: #F1F1F1;
    border-radius: 8px;
    border-color: transparent;
    padding-left: 10px;
    margin-bottom: 10px;
    font-size: 16px;
}

.billingAddressInput::placeholder{
    color: #6F7075;
    font-weight: 400;
}


.successfulPayment{
    width: 100%;
    height: 269px;
    background-color: #EFFAFC;
    border-color: #01A4BD;
    color: #01A4BD;
    border-radius: 12px;

    display: flex;
    justify-content: center;
    align-items: center;

    font-weight: 400;
    font-size: 18px;
}


.bookingPopUpPayBtn{
    background-color: #63CFE51A;
    border-radius: 12px;
    border-color: transparent;
    margin-top: 20px;
    width: 100%;
    height: 48px;
    color: #01A4BD;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
}


.bookingPopUpBtns{
    display: flex;
    flex-direction: row;
    justify-content: end;
    width: 100%;
    gap: 8px;
    font-weight: 500;
    font-size: 16px;
}

.bookingPopUpClose{
    height: 48px;
    width: 125px;
    border-radius: 12px;
    font-size: 16px;

    background-color: white;
    border-color: #01A4BD;
    color: #01A4BD;
    cursor: pointer;
}

.bookingPopUpContinue{
    height: 48px;
    width: 125px;
    border-radius: 12px;
    font-size: 16px;

    background-color: #01A4BD;
    border-color: transparent;
    color: white;
    cursor: pointer;
}

.bookingPopUpContinue:disabled{
    background-color: lightgray;
    cursor: auto;
}


.bookingStateRow{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 14px;
}




.bookingStateCircle{
    height: 24px;
    width: 24px;
    background-color: #01A4BD;
    border-radius: 50%;
    padding: 2px;
    color: white;
    cursor: pointer;
}
.bookingStateCircle.disabled{
    background-color: white;
    color: #858797;
    border-color: #858797;
    border-width: .1px;
    border-style: solid;
    cursor: default;
}

.bookingStateText{
    font-weight: 400;
    font-size: 18px;
    color: #858797;
}
.bookingStateText.current{
    color: black;
}


.bookingStateLine{
    width: 22px;
    height: 1px;
    background-color: #01A4BD;
}
.bookingStateLine.disabled{
    background-color: #858797;
}

.bookingInputs{
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 18px;
}

.bookingInput{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
    gap: 20px;
    /* background-color: #01A4BD; */
    /* width: 100%; */
}

.bookingAddClass{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
    gap: 12px;
    font-weight: 400;
    font-size: 16px;
    

}

.bookingAddClassIcon{
    width: 36px;
    /* margin-right: 210px; */
    cursor: pointer;
}

.bookingColumn{
    display: flex;
    /* flex: 50%; */
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    margin-right: 25px;
}

.bookingTitleRow{
    display: flex;
    flex-direction:row;
    justify-content: flex-start;
    gap: 8px;
}


.bookingDropdown{
    height: 48px;
    width: 200px;

    padding-left: 10px;
    border-radius: 12px;
    border-color: #EBEBEB;
    border-width: 1px;
    border-style: solid;

    font-weight: 400;
    font-size: 16px;
    color: #00000080;

    display: flex;
    justify-content: center;
    align-items: center;
}


.bookingDropdown.error{
    border-color:  red;
}


.datePicker{
    border-color: transparent;
    height: 100%;
    width: 90%;

    font-weight: 400;
    font-size: 16px;
    color: #00000080;
    border-color: transparent;
}

.react-datepicker-wrapper input[type="text"]{
    border: none;
}


.bookingBin{
    height: 22px;
    margin-bottom: 12px;
    cursor: pointer;
}




@media (max-height:900px) {
    .bookingPopUp{
        top:3%
    }
    .bookingPopUpContent{
        max-height: 400px;
    }
}


@media (max-height:750px) {
    .bookingPopUp{
        top:3%
    }
    .bookingPopUpContent{
        max-height: 350px;
    }

    .paymentComponentBPU{
        height: 200px;
    }
}



/* -------------------------------------------- */

.schedulerShadow{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

    background-color: #00000080;
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.schedulerPopUp{
    margin-bottom: 200px;
    width: 635px;
    /* height: 575px; */
    background-color: white;
    border-radius: 12px;
    padding: 32px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
}

.schedulerRightAlign{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    
}




@media (max-width:900px) {
    
    .schedulerPopUp{
        width: 95%;
        box-sizing: border-box;
    }

    .bookingInput{flex-direction: column; align-items: flex-start;}


    .bookingPopUp{
        width: 98%;
        box-sizing: border-box;
        padding: 20px;
    }

    .bookingStateText{display: none;}
    /* .bookingStateText.current{display: inline;} */

    .bookingPopUp1{flex-direction: column;}
    .bookingCouponRow{flex-direction: column; gap: 10px;}
    .bookingCouponBtn{width: 100%;}
    .bookingPopUpJob{text-align: left;}

    .bookingInputs{align-items: flex-start; gap: 50px;}
    .bookingAddClass{margin-top: -30px;}

    .paymentForm{
        flex-direction: column;
    }
   
}