.tutorScreen{
    padding: 60px;
    display: flex;
    flex-direction: column;    
    gap: 30px;
    align-items: center;
    background-color: white;
    /* height: max-content; */
    
}

.tutorScreenContainer{
    max-width: 1500px;
    width: 90%;
    display: flex;
    flex-direction: row;  
    justify-content: stretch;  
    gap: 30px;
    height: max-content;
    /* background-color: #01A4BD; */

}

.tutorMainSection{
    width: 100%;
}

.tutorSideSection{
    height: 3000px;
    width: 350px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    /* background-color: blue; */
}



.tutorPreferences{

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 220px;

    padding: 32px;
    gap: 12px;

    border-radius: 12px;
    border-color: #FAFAFA;
    border-width: 3px;
    border-style: solid;

    font-weight: 400;
    font-size: 16px;
}


.tutorPrefTitle{
    margin-top: 12px;
    color: #858797;
}

.tutorSocialMediaRow{
    display: flex;
    flex-direction: row;
    gap: 24px;
}

.tutorSocialIcon{
    height: 24px;
    width: 24px;
    cursor: pointer;
}





.tutorPriceTitle{
    font-weight: 600;
    font-size: 20px;
    color: black;
    text-align: left;
}


.tutorPriceCell{
    color: #858797;
    gap: 24px;
    
}

.tutorPriceHeaderRow{
    display: flex;
    flex-direction: row;
    gap: 12px;
}
.tutorPriceBubble{
    padding: 4px 12px 4px 12px;
    background-color: #FFC928;
    border-radius: 100px;
    color: white;
    font-size: 12px;
    font-weight: 500;
    height: 26px;

    display: flex;
    justify-content: center;
    align-items: center;
}

.tutorPriceColumn{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    gap: 12px;
}

.tutorPriceRow{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}

.couponTPR{
    margin-top: -20px;
}

.tutorPricePair{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    font-weight: 600;
    color: black;
}

.tutorPriceCurrency{ font-size: 20px; padding-top: 2px;}
.tutorPriceNumber{ font-size: 32px; }

.tprLine{
    position: absolute;
    background-color: #01A4BD;
    width: 147px;
    height: 5px;
    border-radius: 8px;
}

.tutorPriceBtn{
    height: 48px;
    /* width: 125px; */
    background-color: #63CFE51A;

    border-radius: 12px;
    border-color: transparent;

    font-weight: 500;
    font-size: 16px;
    color: #01A4BD;
    padding-left: 10px;
    padding-right: 10px;

    cursor: pointer;
}



.section1TP{
    display: flex;
    flex-direction: row;
    gap: 42px;
    align-items: flex-start;
}

.section1TPimg{
    height: 292px;
    width: 292px;
    border-radius: 12px;
    object-fit: cover;

}

.section1TPInfo{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
}

.section1TPitleRow{
    display: flex;
    flex-direction: row;
    gap: 32px;
}

.section1TPtitle{
    font-weight: 600;
    font-size: 32px;
}

.section1TPbubble{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 13.6px;
    background-color: #34BC851A;
    border-radius: 12px;
    border-color: transparent;
    
    color: #34BC85;
    font-weight: 600;
    font-size: 16px;
    padding: 8px;
}

.section1TPbubbleIcon{
    height: 18px;
    width: 18px;
}

.section1TPsubtext{
    margin-top: 4px;
    font-weight: 500;
    font-size: 18px;
    color: #858797;
}

.section1TPratingrow{
    display: flex;
    flex-direction: row;
    gap: 6px;
}

.section1TPrating{
    font-weight: 400;
    font-size: 18px;
    color: #858797;
}

.section1TPprice{
    font-weight: 500;
    font-size: 18px;
}

.section1TPbtn{
    height: 48px;
    width: 125px;

    background-repeat: 12px;
    background-color: white;
    border-radius: 12px;
    border-color: #01A4BD;

    color: #01A4BD;
    font-weight: 500;
    font-size: 16px;

    margin-top: 44px;
    cursor: pointer;
    transition: all 0.3s;
}

.section1TPbtn:hover{
    background-color: #01A4BD;
    color: white;
}



.section2TP{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 25px;
    margin-top: 38px;
    margin-bottom: 38px;
}

.titleRowTP{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 24px;
}

.titleIconTP{
    height: 40px;
    width: 40px;
}

.titleTextTP{
    font-size: 24px;
    font-weight: 600;
}

.youtubeTP{
    height: 477px;
    width: 848px;
}


.section3TP{
    max-width: 979px;
    height: fit-content;
    padding: 32px;
    border-radius: 12px;
    border-color: #EBEBEB;
    border-width: 1px;
    border-style: solid;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    margin-bottom: 24px;
}

.section3TPsubtext{
    font-size: 14px;
    font-weight: 400;
    color: #858797;
    text-align: left;
}

.workHistoryTP{
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.workHistoryTProw{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-weight: 500;
}

.workHistoryTPtitle{
    font-size: 20px;
    text-align: left;
}

.workHistoryTPdate{
    font-size: 16px;
    text-align: right;
}


.titleRowTPreviews{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.reviewTileTP{
    display: flex;
    flex-direction: row;
    gap:18px;

    width: 100%;
    border-color: transparent;
    border-bottom: #EBEBEB;
    border-width: 2px;
    border-style: solid;
    padding-bottom: 32px;
    margin-bottom: 16px;
}

.reviewTileTPcolumn{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 14px;
}

.reviewTileTProw
{
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
}

.reviewTileTPtext{
    font-size: 16px;
    font-weight: 400;
    color: #858797;
    text-align: left;
}

.showMoreBtnTP{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}


.noReviews{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
}

.noReviewsImg{
    height: 247px;
    width: 223px;
}

.noReviewsText{
    max-width: 312px;
    font-weight: 400;
    font-size: 16px;
}



.addReviewTP{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;

    font-size: 20px;
    font-weight: 500;
    gap: 18px;
}

.addReviewTPinput{
    height: 263px;
    width: 100%;

    border-radius: 12px;
    padding: 12px;
    border-color: #EBEBEB;
    border-width: 1px;
    border-style: solid;
    
    
}

.addReviewTPbtnDiv{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
}


.addReviewTPbtn{
    height: 48px;
    width: 125px;
    background-color: #01A4BD;
    border-radius: 12px;
    border-color: transparent;
    color: white;
    font-weight: 500;
    font-size: 16px;
    cursor: pointer;
}







.addReviewTPsubmitted{
    height: 263px;
    width: 100%;

    border-radius: 12px;
    padding: 12px;
    border-color: #EBEBEB;
    border-width: 1px;
    border-style: solid;
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    color: black;
    font-size: 20px;
    font-weight: 600;
}







.tutorSideSectionMobile{
    display: none;
}



@media (max-width:900px) {

    .tutorScreen{
        padding: 20px;
        padding-top: 40px;
        
    }

    .tutorScreenContainer{flex-direction: column; align-items: center; width: 100%;}

    .section1TP{
        flex-direction: column; 
        align-items: center;
    }
    .section1TPimg{width: 100%; height: auto;}

    .section1TPInfo{width: 100%;}
    .section1TPitleRow{flex-direction: column-reverse;}

    .section1TPbtn{margin-top: 10px;}

    .youtubeTP{width: 100%; height: auto; min-height: 200px;}

    .tutorPreferences.preferences{display: none;}
    .tutorPreferences{width: 100%; box-sizing: border-box;}
    .divDropdown{display: none;}

    .tutorSideSectionMobile{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
        margin-bottom: 20px;
    }

    .tutorSideSection{display: auto;}
}