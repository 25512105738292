.footer{
    min-height: 376px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 31px;
    background-color: #0081FE11;

    
}

.footerLogo{
    height: 49px;
    width: 200px;
}


.footerRoutes{
    display: flex;
    flex-direction: row;
    gap: 56px;

}

.footerRoute{
    color: black;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
}

.footerRoute:hover{
    text-decoration: underline;
}

.footerSocials{
    display: flex;
    flex-direction: row;
    gap: 26px;
}

.socialIcon{
    height: 17px;
}

.footerCopyright{
    color: #0A142FAA;
    font-size: 12px;
    font-weight: 400;
}

@media (max-width:900px) {

    .footerRoutes{flex-direction: column; gap: 10px;}
    .footer{min-height: 506px; margin-top: 50px;}
}