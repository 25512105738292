.homeScreen{
    padding: 60px;
    display: flex;
    flex-direction: column;    
    gap: 30px;
    align-items: center;
    background-color: white;
}

.homeScreenContainer{
    width: 1317px;
    /* max-width: 1500px; */
    display: flex;
    flex-direction: column;    
    align-items: center;
    gap: 80px;
    /* background-color: #01A4BD; */
}

.section1HS{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.section1HSColumn{
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: flex-start;
}

.mainTitleHS{
    
    font-weight: 600;
    font-size: 48px;
    max-width: 600px;
    color: black;
    text-align: left;
}

.mainSubtitleHS{
    font-weight: 400;
    font-size: 16px;
    max-width: 467px;
    color: #858797;
    text-align: left;
}

.section1HSRow{
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
}

.section1HSBtn{
    border-radius: 12px;
    height: 48px;
    font-size: 16px;
    font-weight: 500;
    transition: all 0.2s;
    cursor: pointer;
}

.section1HSBtn.startLearning{
    width: 137px;
    color: white;
    background-color: #01A4BD;
    border-color: transparent;
}

.section1HSBtn.startLearning:hover{
    color: #01A4BD;
    background-color: white;
    border-color: #01A4BD;
}


.section1HSBtn.discoverTutors{
    width: 182px;
    color: #01A4BD;
    background-color: white;
    border-color: #01A4BD;
    border-width: 1px;
    border-style: solid;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
}


.section1HSBtn.discoverTutors:hover{
    color: white;
    background-color: #01A4BD;
    border-color: transparent;
}

.section1HSBtn.discoverTutors.teacherTile{
    position: absolute;
    bottom: 20px;
}


.section1HSimg{
    /* height: 378px;
    width: 536px; */
    width: 40%;
}



.section2HS{
    display: flex;
    flex-direction: column;
    gap:26px;
    margin-top: 50px;
    width: 100%;
}

.section2HSRow1{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    
}

.section2HSIconText{
    display: flex;
    flex-direction: row;
    gap: 24px;
    align-items: center;
    color: #000;
    font-size: 18px;
    font-weight: 600;
}

.section2HSimg{
    height: 56px;
    width: 56px;
}


.section2HSRow2{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 55px;
    min-height: 75px;

    background-color: #CCEDFF;
    border-radius: 16px;
    border-color: transparent;
    padding-right: 10px;
    gap: 20px;
}


.flexRow{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.flexRowBtns{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
}

.section2HSPhone{
    height: 41px;
    width: 41px;
    margin-right: 10px;
}

.section2HStxt{
   font-weight: 500; 
   font-size: 17px;
}

.section2HSBtn{
    width: 175px;
    height: 57px;
    border-radius: 12px;
    background-color:#28527A;
    border-color: transparent;
    color: white;
    font-weight: 400;
    font-size: 16px;
    cursor: pointer;
}

.section2HSBtn:hover{
    /* color: #CCEDFF; */
    border-color: white;
}

.section3HS{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.section3HSimg1{ width: 20.83vw; max-width: 300px; }
.section3HSimg2{ width: 41vw; max-width: 594px; }
.section3HSimg3{ width: 20.92vw; max-width: 301px;}

.section3HSimg1Mobile{ display: none; }
.section3HSimg3Mobile{ display: none;}

.section3HSimg{
    width: 80%;
    /* width: 1218px; */
    /* height: 743px; */
    /* margin-left: 50px; */
}


.section4HS{
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    
}

.section4HSTitle{
    font-weight: 600;
    font-size: 40px;
}

.section4HSSubTitle{
    font-weight: 400;
    font-size: 16px;
    color: #858797;
}

.featuredTeachersRow{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    max-width: 1067px;
    margin-top: 100px;
}


.section5HS{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 80%;
}

.section5HSimg{
    width: 50%;
    /* height: 799px; */
    /* width: 755px; */

}

.section5HSColumn{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
}

.section5HSTitle{
    font-size: 40px;
    font-weight: 600;
}

.section5HSSubTitle{
    font-size: 16px;
    font-weight: 400;
    color: #858797;
    text-align: start;
}

.section5HSIconText{
    height: 80px;
    /* width: 425px; */
    width: 90%;
    
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    gap: 24px;
    padding-left: 42px;

    border-radius: 12px;
    border-width: 2px;
    border-color: #fafafa;
    border-style: solid;

    color: black;
    font-size: 18px;
    font-weight: 500;
    cursor: pointer;
}

.section5HSIconText:hover{
    background-color: #fafafa;
}



.section5HSicon{
    height: 48px;
    width: 48px;
}



.section6HS
{
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.section6HSColumn{
    display: flex;
    flex-direction: column;
}


.section6HSTitle{
    font-size: 40px;
    font-weight: 600;
}

.section56HSSubTitle{
    font-size: 16px;
    font-weight: 400;
    color: #858797;
}

.section6HSimg{
    /* height: 536px;
    width: 725px; */
    width: 50%;
    
}


.section7HS{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    border-radius: 37px;
    border-width: 1px;
    border-color: #EFF0F7;
    border-style: solid;
    width: 90%;
}

.section7HSColumn{
    
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 18px;

    font-weight: 700;
    font-size: 22px;
    padding-left: 20px;
}

.section7HSLogo{
    height: 56px;
    width: 230px;
}

.section7HSinnerColumn{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    padding-left: 10px;
    margin-top: 100px;
}

.section7HSsubtext{
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    color: #6F6C90;
    padding-left: 10px;
}

.section7HSbtn{
    height: 57px;
    width: 236px;

    background-color: #01A4BD;
    border-radius: 52px;
    border-color: transparent;

    color: white;
    font-weight: 700;
    font-size: 16px;

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    cursor: pointer;
}

.section7HSDisclaimers{
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    color: #6F6C90;
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    margin-bottom: 20px;
}


.section7HSimg{
    /* background-color: rebeccapurple; */
    margin-top: 50px;
    width: 60%;
}







.featuredTeacherTile{
    position: relative;

    margin-top: 75px;
    height: 425px;
    width: 313px;
    border-radius: 12px;
    border-width: 1px;
    border-style: solid;
    border-color: #999999;
    
    
    cursor: pointer;
}

.featuredTeacherTile.raised{
    margin-top: 0px;
}






.fttImg{
    width: 100%;
    height: 100%;
    border-radius: 12px;
}

.fttHeart{
    position: absolute;
    top: 13px;
    right: 13px;
    height: 40px;
    width: 40px;
    cursor: pointer;
}


.fttInfo{
    position: absolute;
    height: 142px;
    bottom: 0px;
    left: 0px;
    right: 0px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 15px;
    gap: 12px;

    background-color: white;
    border-radius: 12px;
    border-color: transparent;

    transition: all 0.3s;
    overflow-y: hidden;
}

.fttInfo:hover{
    height: 276px;
}

.fttRow{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.fttBold{
    font-weight: 500;
    font-size: 16px;
}

.fttRating{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;

    font-weight: 400;
    font-size: 14px;
}

.fttStar{
    height: 24px;
    width: 24px;
}

.fttSubText{
    font-size: 14px;
    font-weight: 400;
    color: #858797;
    text-align: left;
}












.reviewSlider{
    height: 318px;
    width: 627px;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    margin-top: 32px;
}

.reviewHorizontal{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 24px;
}

.reviewArrow{
    height: 24px;
    width: 24px;
    cursor: pointer;
}

.reviewArrowContainer{
    width: 24px;
}

.reviewContainer{
    min-height: 268px;
    width: 431px;
    border-radius: 12px;
    border-width: 1px;
    border-style: solid;
    border-color: #EBEBEB;
    padding: 32px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 18px;
}

.reviewStars{
    display: flex;
    flex-direction: row;
    gap: 8px;
}
.reviewStar{
    height: 19px;
}

.reviewStar.reviewInput{
    height: 20px;
    cursor: pointer;
}



.reviewBody{
    font-size: 16px;
    font-weight: 400;
    text-align: left;
}

.reviewBottomSection{
    display: flex;
    flex-direction: row;
    gap: 16px;
}

.reviewImage{
    height: 64px;
    width: 64px;
    border-radius: 50%;
}

.reviewColumn{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
}

.reviewName{
    font-weight: 500;
    font-size: 16px;
}

.reviewSubText{
    font-weight: 400;
    font-size: 14px;
}


.reviewDotRow{
    display: flex;
    flex-direction: row;
    gap: 8px;
    
}

.dot {
    height: 8px;
    width: 8px;
    background-color: #D9D9D9;
    border-radius: 50%;
    display: inline-block;
  }

  .dot.filled{
      background-color: #01A4BD;
  }



  




  
@media (max-width:1500px) {
    .featuredTeacherTile{
        margin-top: 75px;
        height: 373px;
        width: 275px;
    }
}



@media (max-width:900px) {

    .homeScreen{padding: 0px; width: 100%;}
    .homeScreenContainer{max-width: 100%; align-items: center;}


    .section1HS{width: 100%;}
    .section1HSColumn{align-items: center; width: 90%;}

    .section1HSimg{display: none;}
    .mainTitleHS{ 
        margin-top: 30px;
        font-size: 37px;
        text-align: center;
    }
    .mainSubtitleHS{text-align: center;}
    .section1HSRow{flex-direction: column; align-items: center;}
    .section1HSBtn{width: 80%;}

    .section2HS{align-items: center;}
    .section2HSRow1{flex-direction: column; align-items: flex-start; gap: 20px; margin-top: -80px;}
    .section2HSRow2{flex-direction: column; background-color: white;width: 90%; height: auto; padding-left: 0px;}
    .flexRow{background-color: #CCEDFF; border-radius: 8px; padding: 20px;}
    .flexRow.bottomSectionAuth{background-color: white}
    .section2HSPhone{display: none;}
    .flexRowBtns{flex-direction: column; width: 100%;}
    .section2HSBtn{width: 90%;}

    
    .section7HSimg{display: none;}


    .featuredTeachersRow{
        flex-direction: column; 
        align-items: center; 
        margin-top: 50px;
        gap: 20px; 
    }

    .section1HSBtn.discoverTutors
    {
        visibility: hidden;
    }

    .section1HSBtn.discoverTutors.teacherTile
    {
        visibility: visible;
    }

    .section5HSimg{
        display: none;
    }
    
    .section6HSimg{
        display: none;
    }

    .reviewSlider{
        width:100%;
        height: auto;
        /* margin-bottom: 100px; */
    }

    .reviewContainer{
        width: 100%;
    }

    .featuredTeacherTile{
        margin-top: 0px;
        width: 80%;
        height: auto;
    }


    .section3HS{flex-direction: column; align-items: center;}
    .section3HSimg1{display: none;}
    .section3HSimg3{display: none;}

    .section3HSimg1Mobile{display: block; width: 80%; height: auto;}
    .section3HSimg2{width: 80%; height: auto;}
    .section3HSimg3Mobile{display: block; width: 80%; height: auto;}
}
