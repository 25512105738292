.splitScreen{
    height: 100%;
    min-height: fit-content;
    width: 100%;
    display: flex;
    flex-direction: row;
    overflow: auto;
}


.loginImg{
    
    min-width: 10%;
    width:50%;
    height:100%;
    /* height: max-content; */
    object-fit: cover;

}

.formDiv{
    height: 100%;
    min-width:667px;
    width: 50%;
    
    align-items: center;
    justify-content: center;
}



.flexColumnAuth{
    display: flex;
    flex-direction: column;
    gap: 11px;
    
}


.flexRowAuth{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}


.flexRow{
    display: flex;
    flex-direction: row;

}


/* .registerScreen{
    height: max-content;
    padding-top: 30px;
    padding-bottom: 300px;
    box-sizing: border-box;
} */

.titleTextAuth{
    font-size: 32px;
    font-weight: 500;
    color: #0B0F2E;
}

.subTitleTextAuth{
    font-size: 18px;
    font-weight: 400;
    color: #858797;
}

.headerTextAuth{
    font-size: 16px;
    font-weight: 500;
    color: #0B0F2E;
    text-align: left;
}

.smallTextAuth{
    font-size: 14px;
    font-weight: 400;
    color: #0B0F2E;
}


.buttonAuth{
    color: white;
    background-color: #01A4BD;
    border-radius: 9px;
    font-size: 15px;
    width: 100%;
    height: 60px;
    margin-top: 28px;
    text-align: center;
    border-color: transparent;
    cursor: pointer;
}

.bottomSectionAuth{
    gap:5px;
    justify-content: center;
    font-weight: 400;
}

.width370{
    width: 370px;
}

.gap6{
    gap: 6px;
}

.gap63{
    gap: 63px;
}

.inputAuth{
    height: 62px;
    background-color: #FBFBFE;
    border-radius: 8px;
    border-color: transparent;
    padding-left: 10px;
    font-size: 15px;
}

.inputAuth::placeholder{
    color: #C5C9CA;
    font-size: 15px;
    font-weight: 400;
}

.blueTextAuth{
    color: #01A4BD;
    font-weight: 600;
    cursor: pointer;
}


.loginError{
    /* min-height: 25px; */
    color: red;
    font-weight: 300;
    margin-top: 20px;
}

.forgotPasswordBtn
{
    cursor: pointer;
}

.forgotPasswordBtn:hover
{
    text-decoration: underline;
}



.loginOptionButton{
    width: 100%;
    height: 52px;

    border-radius: 9px;
    border-color: #01A4BD;
    border-width: 1px;
    border-style: solid;

    padding-left: 19px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 11px;

    color: #3C4043;
    font-weight: 500;
    font-size: 18px;

}

.loginOptionImg{
    /* height: 24px; */
    width: 20px;
}




@media (max-width:1000px) {
    .splitScreen{
        flex-direction: column;
        overflow-y: auto;
        padding-bottom: 100px;
    }
    .loginImg{
        width: 100%;
        height: 20%;
    }

    .formDiv{
        width: 100%;
        justify-content: flex-start;
        margin-top: 130px;
        min-width: 0px;
    }
    
}
