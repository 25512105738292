
.practiceScreen{
    height: 100%;
    background-color: #F4F5F9; 
    display: flex;
    flex-direction: column;
    align-items: center;
}

.practiceContainer{
    max-width: 1440px;
}

.practiceTest{
    margin-top: 108px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 163px;
}





.questionPP{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 91px;

    font-weight: 500;
    font-size: 37px;
    color: #091F3D;
    min-height: 346px;
}


.answersGridPP{
    display: grid;
    grid-template-columns: auto auto;
    row-gap: 21px;
    column-gap: 22px;
}


.answerPP{
    display: flex;
    gap: 21px;
    justify-content: start;
    align-items: center;
    padding: 22px;
    width: 500px;
    box-sizing: border-box;

    font-size: 16px;
    font-weight: 500;

    border-radius: 10px;
    background-color: #E5E8EF;
    
    cursor: pointer;
}



.answerPP.disabled{
    cursor: auto;
    background-color: #E5E8EFAA;
    color: #091F3DAA;
}

.answerPP.disabled.wrongAnswer{
    background-color: #FF6961;
}
.answerPP.disabled.correctAnswer{
    background-color: #96D6A2;
}

.answerBox{
    height: 17px;
    width: 17px;
    border: #091F3D solid 2px;
    border-radius: 4px;
}



.progressBarPP{
    width: 95%;
    margin-left: 935px;
    margin-right: 935px;
    box-sizing: border-box;

    border-radius: 15px;
    background-color: white;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}


.progressBarRow{
    width: 100%;
    display: flex;
    align-items: center;
    gap: 34px;
}

.progressText{
    font-weight: 500;
    font-size: 13px;
    margin-top: -15px;
}

.progressBar {
    width: 100%;
    height: 4px;
    background: #00229A;
    border-radius: 20px;
  }
  


  .slider{
    -webkit-appearance: none;
    width: 100%;
    height: 4px;
    border-radius: 20px;
    background-color: #00229A;
  }
  
  /* .slider::-webkit-slider-runnable-track {
    height: 4px;
    background-color: #ddd;
  } */

  /* .slider::-moz-range-track {
    height: 4px;
    background: #ddd;
  } */
  

.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 14px;
    height: 14px;
    border-radius: 50%; 
    background: #63CFE5;
    cursor: pointer;
  }



/*   
  .slider::-moz-range-thumb {
    -webkit-appearance: none;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: #63CFE5;
    cursor: pointer;
  } */


.nextQuestionBtnPP{
    width: 216px;
    height: 48px;
    color: white;
    border-radius: 11px;
    background-color: #091F3D;

    display: flex;
    justify-content: end;
    align-items: center;
    gap: 25px;

    font-size: 13px;
    font-weight: 700;
    cursor: pointer;
}

.nextQuestionBtnPP.disabled{
    cursor: auto;
    background-color: #091F3D77;
}

.nextQuestionBtnArrow{
    height: 10px;
    margin-right: 20px;
}



.practiceMainScreen{
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-top: 50px;
}




.practiceAnalytics{

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 30px;
    box-sizing: border-box;
    width: 100%;

    border-radius: 15px;
    border-color: #091F3D;
    border-width: 2px;
    border-style: solid;

    font-size: 21px;
    font-weight: 400;
    color: #091F3D;
}

.paBold{font-weight: 500;}

.paChartRow{
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 50px;
}

.paChart{
    height: 250px;
}














/* --------------------------------------------------------------------------------------- */


.editTutorScreen{
    flex: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 50px;
}



.editTutorTest{
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 900px;
    min-width: 500px;
    gap: 20px;
}

.editTestTitle{
    margin-top: 50px;
    margin-bottom: 50px;
    font-size: 30px;
    font-weight: 500;

}

.editTutorQuestion{
    width: 100%;
    height: fit-content;
    padding: 20px;
    box-sizing: border-box;
    border-radius: 20px;
    border: #4AAB98 1px solid;

    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;

}

.etqDelete{
    display: flex;
    width: 100%;
    justify-content: end;
}


.editAnswersGrid{
    display: grid;
    grid-template-columns: auto auto;
    row-gap: 21px;
    column-gap: 22px;
    box-sizing: border-box;
}

.editAnswerRow{
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 20px;
    font-weight: 500;
}


.editAnswerRow.addClass{
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 17px;
    font-weight: 500;
}

.selectAnswer{
    font-size: 17px;
    font-weight: 300;
}

.inputAnswer{
    -webkit-appearance: none;
    

    width: 300px;
    border-color: black;
    border-width: 1px;
    border-style: solid;
    padding: 10px;
    border-radius: 10px;

    font-size: 18px;
    font-weight: 300;
}

.inputAnswer.question{width: 90%;}


.binIcon{
    cursor: pointer;
    height: 15px;
}


.saveTestBtn{
    height: 48px;
    width: 125px;
    background-color: #01A4BD;
    border-radius: 12px;
    border-color: transparent;
    color: white;
    font-weight: 500;
    font-size: 16px;
    cursor: pointer;
}

.saveTestBtn:disabled{
    background-color: #E5E8EF;
    cursor: auto;
}

.addTestButton{
    height: 100%;
    width: 200px;
    background-color: #F4F5F9;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    cursor: pointer;
}

.addTestIcon{
    height: 30px;
    font-size: 30px;
}