
.paymentShadow{
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

    background-color: #00000080;
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 100;
}

.paymentPopUp{
    margin-bottom: 200px;
    width: 635px;
    /* height: 575px; */
    background-color: white;
    border-radius: 12px;
    padding: 32px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
}

.paymentRightAlign{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    
}


.paymentPopUpCancel{
    cursor: pointer;
    height: 24px;
}



.paymentPopUpSubmit{
    height: 48px;
    width: 125px;
    border-radius: 12px;

    background-color: #01A4BD;
    border-color: transparent;
    color: white;
    cursor: pointer;
}



.buyClassesTitle{
    font-size: 59px;
    font-weight: 700;
    color: #120B46;
}


.buyClassesSubtitle{
    font-size: 18px;
    font-weight: 400;
    color: #6D678E;
    margin-top: 25px;
    margin-bottom: 33px;
}






.planTileSection{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 7px;
}

.planTile{
    /* height: 360px; */
    /* width: 592px; */
    border-radius: 8px;
    background-color: #D6F2FF;
    color: #120B46;
    padding: 25px 30px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 34px;
}

.planTile.Professional{
    background-color: #BDEAE8;
}

.planTileContent{
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-top: 12px;
}

.planTileLeft{
    width: 266px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: flex-start;
    gap: 9px;

    font-weight: 500;
    font-size: 19px;
}

.planTileTitle{
    height: 39px;
    width: 128px;

    border-radius: 6px;
    border-color: #120B46;
    border-style: solid;
    

    font-weight: 600;
    font-size: 15px;

    display: flex;
    justify-content: center;
    align-items: center;
}


.planTileCost{
    font-weight: 600;
    font-size: 80px;
    margin-top: 10px;
}


.planTileLine{
    margin-left: 10px;
    margin-right: 36px;
    height: 203px;
    width: 1px;
    background-color: #9C9C9C;
}

.planTileRight{
    display: flex;
    flex-direction: column;
    gap: 31px;
    margin-right: 50px;
}

.planTileRow{
    display: flex;
    flex-direction: row;
    gap: 13px;
    align-items: center;

    font-weight: 400;
    font-size: 16.5px;
}

.planTileCheck{
    height: 15px;
    width: 15px;
}

.planTileBtn{
    width: 100%;
    height: 61px;

    background-color: #120B46;
    color: white;
    border-radius: 10px;

    font-weight: 400;
    font-size: 18px;

    cursor: pointer;
}


.buyScreenPlanRow{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 43px;
}



/* ----------------------------------------------------------------------- */


.noCreditScreen{
    position: fixed;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
    z-index: 100;

    background-color: #BDEAE8;
    color: #28527A;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.noCreditText1{
    font-size: 60px;
    font-weight: 500;   
}


.noCreditText2{
    font-size: 50px;
    font-weight: 500;
    margin-top: 94px;
}

.noCreditText3{
    font-size: 50px;
    font-weight: 300;
    margin-top: 24px;
}

